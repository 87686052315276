import { AUTH_GET } from "@grudder/app/actions-server";
import { config } from "@grudder/lib/config";

let apiAbortController: AbortController | null = null;

const getConfig = async (ConfigType?: string) => {
  // Cancel previous request if it exists
  if (apiAbortController) {
    apiAbortController.abort();
  }

  // Create a new AbortController for the current request
  apiAbortController = new AbortController();
  try {
    const url = new URL(`${config.baseURL}/api/internal-integration-configs`);
    if (ConfigType) {
      url.searchParams.set("source", ConfigType);
    }
    const response = await AUTH_GET(url.toString(), {
      revalidate: 36000,
      tags: ["task", ConfigType],
      signal: apiAbortController?.signal,
    });
    apiAbortController = null; // Clear the controller after successful request
    return response;
  } catch (error: any) {
    console.log({ error });
    if (error.name === "AbortError") {
      // Handle fetch cancellation
      console.log("Fetch request canceled");
    } else {
      // Handle other errors
      throw error;
    }
  }
};

export default getConfig;
