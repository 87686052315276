import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
import { AUTH_GET, AUTH_PUT } from "@grudder/app/actions-client";
import { config } from "@grudder/lib/config";

interface UserSettingState {
  userSettings: any[];
  loading: boolean;
  error: string | null;
  isOrderChanged: boolean;
  columnOrder: string[];
}

// Define the initial state
const initialState: UserSettingState = {
  userSettings: [],
  loading: false,
  error: null,
  isOrderChanged: false,
  columnOrder: [],
};

/**
 * Validates settings object structure before sending to API
 * @param settings Settings to validate
 * @returns True if valid, false otherwise
 */
const validateSettings = (settings: any): boolean => {
  // Add validation logic here based on your API requirements
  // Example: Check if required fields exist
  if (!settings) return false;

  // For TABLE_CONFIG type, ensure config array exists and is properly structured
  if (settings.attributes && settings.attributes.config) {
    if (!Array.isArray(settings.attributes.config)) {
      return false;
    }

    // Add more specific validation as needed
  }

  return true;
};

// Fetch user settings async thunk
export const fetchUserSettings = createAsyncThunk(
  'userSettings/fetchUserSettings',
  async (_, { rejectWithValue }) => {
    try {
      console.log('Fetching user settings from API');
      const response = await AUTH_GET(`${config.baseURL}/api/users/user-settings`);
      console.log('User settings fetched:', response);
      return response;
    } catch (error: any) {
      console.error("Error fetching user settings:", error);
      return rejectWithValue(error);
    }
  }
);

// Update user settings async thunk
export const updateUserSettings = createAsyncThunk(
  'userSettings/updateUserSettings',
  async (payload: { settingTypeId: string, settings: any }, { rejectWithValue }) => {
    try {
      // Ensure settings.config is an array, not an object with numeric keys
      const settingsToSend = { ...payload.settings };

      // If we have a config property but it's not an array, convert it
      if (settingsToSend.config && !Array.isArray(settingsToSend.config)) {
        settingsToSend.config = Object.values(settingsToSend.config);
      }

      // Validate the settings
      if (!validateSettings(settingsToSend)) {
        return rejectWithValue({
          message: 'Invalid settings format. Please check your settings structure.',
          errorCode: 400
        });
      }

      console.log('Sending API request with payload:', settingsToSend);

      const response = await AUTH_PUT(
        `${config.baseURL}/api/users/user-settings/${payload.settingTypeId}`,
        settingsToSend
      );

      console.log('Settings updated successfully:', response);
      return response;
    } catch (error: any) {
      console.error("Error updating user settings:", error);
      // Improve error handling by extracting more information if available
      const errorMessage = error?.message || 'Failed to update user settings';
      return rejectWithValue({ message: errorMessage, errorCode: error?.errorCode || 500 });
    }
  }
);

// Define the slice
const userSettingSlice = createSlice({
  name: "userSettings", // Changed from userSetting to userSettings for consistency
  initialState,
  reducers: {
    setUserSettings: (state, action: PayloadAction<any[]>) => {
      state.userSettings = action.payload;
    },
    setOrderChanged: (state, action: PayloadAction<boolean>) => {
      state.isOrderChanged = action.payload;
    },
    resetOrderChanged: (state) => {
      state.isOrderChanged = false;
    },
    // New action to update column order
    setColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.columnOrder = action.payload;
      state.isOrderChanged = true; // Mark that order has changed
    },
    // Update the fields in the user settings based on the column order
    updateFieldsOrder: (state, action: PayloadAction<string[]>) => {
      const newOrder = action.payload;
      const tableConfigSetting = state.userSettings.find(
        (setting) => setting?.settingsType === "TABLE_CONFIG" && setting?.value === "lead"
      );

      if (tableConfigSetting && tableConfigSetting.attributes?.config) {
        // Create a map for fast lookups by accessorKey
        const configMap = new Map(
          tableConfigSetting.attributes.config.map((item: any) => [item.accessorKey, item])
        );

        // Create a new config array with the items ordered according to newOrder
        // but only if the keys exist in the current config
        const newConfig = newOrder
          .filter(key => configMap.has(key))
          .map(key => configMap.get(key));

        // Add any items that weren't in the newOrder to preserve all settings
        const includedKeys = new Set(newOrder);
        const remainingItems = tableConfigSetting.attributes.config.filter(
          (item: any) => !includedKeys.has(item.accessorKey)
        );

        // Update the config with the new order
        tableConfigSetting.attributes.config = [...newConfig, ...remainingItems];
        state.isOrderChanged = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchUserSettings actions
      .addCase(fetchUserSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.userSettings = action.payload?.userSettings || [];
        // Initialize columnOrder from fetched settings
        const tableConfigSetting = action.payload?.userSettings?.find(
          (setting: any) => setting?.settingsType === "TABLE_CONFIG" && setting?.value === "lead"
        );

        if (tableConfigSetting?.attributes?.config) {
          // Fixed columns always come first
          const fixedColumns = ['select', 'fullName'];

          // Extract accessorKeys from config
          const configKeys = tableConfigSetting.attributes.config
            .filter((item: any) => item.show && !fixedColumns.includes(item.accessorKey))
            .map((item: any) => item.accessorKey);

          state.columnOrder = [...fixedColumns, ...configKeys];
        }
      })
      .addCase(fetchUserSettings.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to fetch user settings';
      })
      // Handle updateUserSettings actions
      .addCase(updateUserSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        state.loading = false;
        // Update the store with the new settings if available in the response
        if (action.payload?.userSettings) {
          state.userSettings = action.payload.userSettings;

          // Update column order from the updated settings
          const tableConfigSetting = action.payload.userSettings.find(
            (setting: any) => setting?.settingsType === "TABLE_CONFIG" && setting?.value === "lead"
          );

          if (tableConfigSetting?.attributes?.config) {
            // Fixed columns always come first
            const fixedColumns = ['select', 'fullName'];

            // Extract accessorKeys from config in the correct order
            const configKeys = tableConfigSetting.attributes.config
              .filter((item: any) => item.show && !fixedColumns.includes(item.accessorKey))
              .map((item: any) => item.accessorKey);

            state.columnOrder = [...fixedColumns, ...configKeys];
          }
        }
        state.isOrderChanged = false;
        // Reset the flag after successful update
      })
      .addCase(updateUserSettings.rejected, (state, action: any) => {
        state.loading = false;
        // Improve error handling by providing more detailed error messages
        state.error = action.payload?.message || 'Failed to update user settings';
        console.error("Settings update rejected:", action.payload);
      })
      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const {
  setUserSettings,
  setOrderChanged,
  resetOrderChanged,
  setColumnOrder,
  updateFieldsOrder
} = userSettingSlice.actions;

export default userSettingSlice.reducer;
