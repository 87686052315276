import { AUTH_GET } from "@grudder/app/actions-client";
import { config } from "@grudder/lib/config";

let apiAbortController: AbortController | null = null;

const getActivities = async (
  limit: number = 10,
  skip: number = 0,
  leadId: string | null,
  activityTypes: string[] = []
) => {
  // Cancel previous request if it exists
  if (apiAbortController) {
    apiAbortController.abort();
  }

  // Create a new AbortController for the current request
  apiAbortController = new AbortController();
  try {
    const tags = ["activities"];
    const url = new URL(
      `${config.baseURL}/api/activities?limit=${limit}&skip=${skip}`
    );
    if (leadId) {
      url.searchParams.set("lead", leadId);
      tags.push("lead");
      tags.push(leadId);
      tags.push(`${leadId}-activities`);
    }
    let URLString: string = url?.toString();
    if (activityTypes?.length !== 0) {
      const filterURL = activityTypes.map((n) => `name=${n}`).join("&");
      URLString = `${URLString}&${filterURL}`;
    }
    const response = await AUTH_GET(URLString, {
      revalidate: 60,
      tags,
      signal: apiAbortController?.signal,
    });

    console.log({ enquiries1: response });
    apiAbortController = null; // Clear the controller after successful request
    return response;
  } catch (error: any) {
    console.log({ error });
    if (error.name === "AbortError") {
      // Handle fetch cancellation
      console.log("Fetch request canceled");
    } else {
      // Handle other errors
      throw error;
    }
  }
};

export default getActivities;
