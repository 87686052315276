import { clsx, type ClassValue } from "clsx";
import dayjs, { Dayjs } from "dayjs";
import { twMerge } from "tailwind-merge";

interface Permission {
  _id: string;
  featureName: string;
  label: string;
  isEnabled: boolean;
  value: boolean;
  allowedUserTypes?: string[];
  user?: string[];
  createdAt?: string;
  updatedAt?: string;
  parentPermission?: any;
  disabled?: boolean;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export const areQueryStringsEqual = (
  queryString1: string,
  queryString2: string
): boolean => {
  // Decode the query strings
  const decodedQueryString1: string = decodeURIComponent(queryString1);
  const decodedQueryString2: string = decodeURIComponent(queryString2);

  // Split the query strings into key-value pairs
  const params1: URLSearchParams = new URLSearchParams(decodedQueryString1);
  const params2: URLSearchParams = new URLSearchParams(decodedQueryString2);

  // Convert the key-value pairs to objects for easy comparison
  const obj1: { [key: string]: string } = {};
  const obj2: { [key: string]: string } = {};

  params1.forEach((value, key) => {
    obj1[key] = value;
  });

  params2.forEach((value, key) => {
    obj2[key] = value;
  });

  // Compare the objects
  const areEqual: boolean = JSON.stringify(obj1) === JSON.stringify(obj2);

  return areEqual;
};

export const getInitials = (fullName: string) => {
  if (!fullName) return "GR";

  // Check if the fullName contains only non-alphabetic characters
  const isNonAlphabetic = fullName
    .split(" ")
    .every((name) => !/^[a-zA-Z]/.test(name));

  // If the fullName is non-alphabetic, return the first character of fullName
  if (isNonAlphabetic) {
    return fullName?.slice(0, 2).toUpperCase() || "GR";
  }

  // Otherwise, extract initials from alphabetic characters and ignore "symbols"
  const initials = fullName
    .split(" ")
    .map((name) => {
      // Ignore "symbols" and check for alphabetic characters
      const firstAlphaChar = name.match(/[a-zA-Z]/);
      return firstAlphaChar ? firstAlphaChar[0] : "";
    })
    .join("")
    .slice(0, 2)
    .toUpperCase();

  return initials || "GR";
};
export function readableDate(
  date: Dayjs,
  format: string = "MMM DD YYYY, hh:mm A"
): string {
  return dayjs(date).format(format);
}
export const toCamelCase = (str: string) => {
  return str
    ?.toLowerCase() // Convert the entire string to lowercase
    .replace(/[^a-zA-Z0-9 ]+/g, "") // Remove all non-alphanumeric characters except spaces
    .split(" ") // Split the string by spaces
    .map((word, index) => {
      if (index === 0) {
        return word; // Keep the first word in lowercase
      }
      return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first letter of subsequent words
    })
    .join(""); // Join all words into a single string
};
export const formatNumber = (num: number) => {
  if (num < 10) {
    return "0" + num;
  }
  return num.toString();
};
export const parseIfJson = (str: string): { isJson: boolean; value: any } => {
  try {
    const parsed = JSON.parse(str);
    return { isJson: true, value: parsed };
  } catch (_e) {
    return { isJson: false, value: str };
  }
};

export const groupByLeadGroup = (
  cdt: any[]
): { key: string; name: string; data: any[] }[] => {
  const groupedModules: { [key: string]: any[] } = {};

  cdt.forEach((module) => {
    const leadGroup =
      module.leadGroup.replaceAll(" ", "") === "" ? "hidden" : module.leadGroup;
    if (groupedModules[leadGroup]) {
      groupedModules[leadGroup].push(module);
    } else {
      groupedModules[leadGroup] = [module];
    }
  });

  return Object.keys(groupedModules)
    ?.filter((items) => items !== "hidden")
    .map((key) => ({
      key: key,
      name:
        key === "testModules"
          ? "Test Modules"
          : key === "generalData"
          ? "General"
          : "Qualifying Question",
      data: groupedModules[key],
    }))
    ?.sort((a, b) => a?.name.localeCompare(b?.name));
};
export const groupByLeadGroup2 = (
  cdt: any[]
): { key: string; name: string; data: any[] }[] => {
  return cdt?.map((cdtItem: any) => ({
    key: cdtItem?._id,
    name: cdtItem?.name,
    data: cdtItem?.customFields,
  }));
};
export function getAllKeys(array: any[]) {
  // Use Array.reduce to accumulate keys
  return array.reduce((keys, obj) => {
    // Iterate over keys of current object
    Object.keys(obj).forEach((key) => {
      // Check if key is already in keys array
      if (!keys.includes(key)) {
        // If not, add it to keys array
        keys.push(key);
      }
    });
    // Return accumulated keys
    return keys;
  }, []);
}

export function formatPermissions(data: any[]) {
  const result: any[] = [];
  const map: any = {};

  data?.forEach((item) => {
    map[item._id] = { ...item, children: [] };
  });
  data?.forEach((item) => {
    if (!!item.parentPermission) {
      map[item.parentPermission?._id]?.children.push(map[item._id]);
    } else {
      result.push(map[item._id]);
    }
  });
  return result;
}
export function updatePermissionState(
  data: Permission[],
  permissionId: string,
  enabled: boolean
): Permission[] {
  const updatedData: Permission[] = JSON.parse(JSON.stringify(data)); // Deep clone the data to avoid direct mutation

  // Function to recursively update child permissions
  const updateEnabledState = (id: string, value: boolean): void => {
    const permission = updatedData.find((p) => p._id === id);

    // Skip updating if permission has disabled: true
    if (permission && !permission.disabled) {
      permission.value = value;

      updatedData.forEach((child) => {
        if (child.parentPermission?._id === id && !child.disabled) {
          updateEnabledState(child._id, value);
        }
      });
    }
  };

  // Function to recursively update parent permissions
  const updateParentState = (id: string): void => {
    const permission = updatedData.find((p) => p._id === id);

    if (permission && permission.parentPermission?._id) {
      const parent = updatedData.find(
        (p) => p._id === permission.parentPermission?._id
      );

      if (parent && !parent.disabled) {
        const children = updatedData.filter(
          (p) => p.parentPermission?._id === parent._id && !p.disabled
        );

        const atLeastOneIsChecked = children.some((child) => child.value);
        parent.value = atLeastOneIsChecked;
        updateParentState(parent._id); // Recursively update the parent's parent
      }
    }
  };

  // Begin the recursive update process
  updateEnabledState(permissionId, enabled);
  updateParentState(permissionId);

  return updatedData;
}

// Format permissions into a hierarchical structure
export function formatPermissionsForReview(data: any[]) {
  const result: any[] = [];
  const map: any = {};

  data?.forEach((item) => {
    map[item._id] = { ...item, children: [] };
  });
  data?.forEach((item) => {
    if (!!item.parentPermission) {
      map[item.parentPermission?._id]?.children.push(map[item._id]);
    } else {
      result.push(map[item._id]);
    }
  });

  // Remove empty children arrays for items without children
  const cleanEmptyChildren = (items: any[]) => {
    items.forEach((item) => {
      if (item.children.length === 0) {
        delete item.children;
      } else {
        cleanEmptyChildren(item.children);
      }
    });
  };

  cleanEmptyChildren(result);

  return result;
}

// Count total nodes including children, except for the last node in each list
function countNodesTwo(node: any): number {
  if (!node.children || node.children.length === 0) {
    return 1;
  }

  return node.children.reduce(
    (acc: number, child: any) => acc + countNodesTwo(child),
    1
  );
}
function countNodesOne(node: any, isLastChild: boolean): number {
  if (!node.children || node.children.length === 0) {
    return 1;
  }
  if (isLastChild) {
    return 1;
  }
  return node.children.reduce(
    (acc: number, child: any) => acc + countNodesTwo(child),
    1
  );
}

// Calculate height based on count of nodes
export function calculateHeight(nodeArray: any[]) {
  if (!nodeArray || nodeArray.length === 0) {
    return 0;
  }
  const totalNodes = nodeArray.reduce(
    (acc: number, node: any, index: number) =>
      acc + countNodesOne(node, index === nodeArray.length - 1),
    0
  );
  // Each node (excluding the last one) contributes 22px to the height
  const height =
    (totalNodes - nodeArray.length) * 32 + nodeArray.length * 32 - 12;
  return height;
}

//generate random id
export const generateRandomId = () => Math.random().toString(36).substring(2, 15);
