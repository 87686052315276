import { AUTH_GET } from "@grudder/app/actions-server";
import { config } from "@grudder/lib/config";

const getStageCount = async (tags: string[]) => {
  try {
    const url = new URL(`${config.baseURL}/api/dashboards/lead-counts/stages`);

    const data = await AUTH_GET(url.toString(), {
      revalidate: 120,
      tags: tags,
    });
    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
export default getStageCount;
